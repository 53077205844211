<template>
  <div class="couponBox">
    <div class="content">
      <div class="couponBox_tab">
        <van-tabs v-model="active" sticky @click="toggle">
          <van-tab :title="item.title" v-for="(item,ins) in tabData" :key="ins">
            <van-list
              v-model="isUpLoading"
              :finished="upFinished"
              :immediate-check="false"
              :offset="10"
              finished-text="没有更多了"
              @load="onLoadList"
              style="padding:15px 15px 50px 15px;"
            >
              <div class="coupon_list" v-if="couponList.length!=0">
                <div
                  class="coupon_list_bj u-border-bottom"
                  v-for="(item, index) in couponList"
                  :key="index"
                >
                  <div class="mark" :style="{opacity:0.5}" v-if="type!=0"></div>
                  <div class="coupon_list_bj_left">
                    <div class="coupon_list_bj_left_num">
                      <text>￥</text>
                      {{item.consumer_reduce_money}}
                    </div>
                    <div class="coupon_list_bj_left_text">
                      <!-- <div
                      class="coupon_list_bj_left_text1"
                      >{{getCardType(item).limit}}</div>-->
                      <!-- <div class="coupon_list_bj_left_text1">先领券·再使用</div> -->
                      <div
                        class="coupon_list_bj_left_text2"
                      >满{{item.consumer_full_money}}减{{item.consumer_reduce_money}}</div>
                      <div class="coupon_list_bj_left_text3">
                        <div class>活动时间</div>
                        <div class="time">{{item.use_began_date}} - {{item.use_end_date}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="coupon_list_bj_right">
                    <div class="coupon_list_bj_right_use" @click="useGet(item)">去使用</div>
                    <!-- <div class="coupon_list_bj_right_use" v-else>
							{{['待使用','已使用','已过期'][current]}}
                    </div>-->
                  </div>
                </div>
              </div>
              <van-empty description="暂无优惠券" v-else />
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { userCouponLst } from "../../api/coupon";
export default {
  data() {
    return {
      tabData: [
        {
          title: "未使用",
          type: 1,
        },
        {
          title: "已使用",
          type: 2,
        },
        {
          title: "已过期",
          type: 3,
        },
      ],
      active: 0,
      nullTip: "空空如也~",
      limit: 10, // 每页条数
      page: 1, // 页码
      type: 0,
      couponList: [],
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
    };
  },
  mounted() {
    this.userCouponLst();
  },
  methods: {
    toggle(name) {
      // console.log(name);
      this.type = name;
      this.page = 1;
      this.limit = 10;
      this.upFinished = false;
      this.couponList = [];
      this.userCouponLst();
    },
    userCouponLst() {
      let params = {
        page: this.page,
        limit: this.limit,
        status: this.type,
      };
      userCouponLst(params).then((res) => {
        // console.log(res);
        const rows = res.data.list;
        if (rows.length === 0) {
          // 加载结束
          this.upFinished = true;
          return;
        }
        if (rows.length < this.limit) {
          // 最后一页不足10条的情况
          this.upFinished = true;
        }
        // 处理数据
        if (this.page < 2) {
          this.couponList = rows;
        } else {
          this.couponList = this.couponList.concat(rows);
        }
      });
    },
    useGet(item) {
      // console.log(item);
      this.$router.push({
        path: "/h5/shopList",
        query: {
          oid: item.oid,
        },
      });
    },
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.userCouponLst();
    },
  },
};
</script>
<style scoped lang="scss">
.couponBox {
  width: 100%;
  //   padding: 0 15px;
  .coupon_list {
    width: 100%;
    .coupon_list_bj {
      background-image: url(../../assets/image/coupon.png);
      width: 100%;
      height: 110px;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      margin-bottom: 15px;
      .mark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
      }
      .coupon_list_bj_left {
        flex: 1;
        display: flex;
        align-items: center;
        .coupon_list_bj_left_num {
          width: 110px;
          padding-left: 20px;
          text-align: center;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #fe6e04;

          text {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fe6e04;
          }
        }
        .coupon_list_bj_left_text {
          flex: 1;
          text-align: center;

          .coupon_list_bj_left_text1 {
            font-size: 10px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fe6e04;
          }

          .coupon_list_bj_left_text2 {
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #fe6e04;
            margin: 5px 0;
          }

          .coupon_list_bj_left_text3 {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #fe6e04;
            .time {
              font-size: 12px;
            }
          }
        }
      }
    }
    .coupon_list_bj_right {
      width: 70px;
      // position: relative;
      // height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .coupon_list_bj_right_use {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #fe6e04;
      }
    }
  }
}
</style>